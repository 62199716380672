import React from "react";
import DataStructureHooks from "./hooks/DataStructureHooks";
import TextFieldReusable from "../../components/textfields/TextFieldReusable";
import ButtonReusable from "../../components/buttons/ButtonReusable";

const DataStructure = () => {
  const { ...param } = DataStructureHooks();
  console.log("param.data:", param.data);
  return (
    <div style={{ display: "flex", gap: "20px" }}>
      {Array.from(param.data.keys()).map((fieldName) => (
        <TextFieldReusable
          key={fieldName}
          name={fieldName}
          value={param.data.get(fieldName)}
          onChange={(e) => param.handleChange(e)}
        />
      ))}

      <ButtonReusable
        buttonName="click"
        variant="contained"
        onClick={() => param.handleMapItems()}
      />
    </div>
  );
};

export default DataStructure;
