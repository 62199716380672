import React from "react";
import "./css/genbtnstyling.css";
import { FaPlay } from "react-icons/fa6";
import { IconButton } from "@mui/material";

const PulseIconButton = () => {
  return (
    <div class="play-button">
      <div class="layer"></div>
      <div class="layer"></div>
      <div class="layer"></div>
      <IconButton>
        <FaPlay className="play" />
      </IconButton>
    </div>
  );
};

export default PulseIconButton;
