import { GLOBAL_ACTION } from "../pages/generalReducer/constant/GlobalConstant";

const initialState = {
  isDialogOpen: null,
  lightbox_status: null,
  newRoute: "",
  inquiry: {
    inquirer_name: "",
    inquirer_email: "",
    inquirer_message: "",
  },
  testObject: {
    test_name: "",
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
