import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
const TextFieldReusable = (props) => {
  return (
    <div>
      <Typography sx={{ fontSize: "12px" }}>
        {props.title} <span style={{ color: "red" }}>{/* <sup>*</sup> */}</span>
      </Typography>
      <TextField
        disabled={props.disabled}
        id="outlined-basic"
        label={props.label}
        variant={props.variant}
        autoComplete="off"
        size={props.size}
        type={props.type}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        multiline={props.multiline}
        rows={props.rows}
        fullWidth={props.fullWidth}
        required={props.required}
        sx={{
          backgroundColor: "#F5F5F5",
          borderRadius: "0px",
          border: 0,
          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#454545", // Customize the onFocus color here
            // borderRadius: "0px",
            // opacity: [0.9, 0.8, 0.7],
          },
          "&:hover": {
            // backgroundColor: "primary.main",
            // opacity: [0.9, 0.8, 0.3],
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderWidth: 0, // Removes border when focused
            },
            "&:hover fieldset": {
              borderWidth: 0, // Removes border on hover
            },
            "& fieldset": {
              borderWidth: 0, // Removes default border
            },
          },
        }}
      />
    </div>
  );
};

export default TextFieldReusable;
