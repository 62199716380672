import React from "react";
import { Grid } from "@mui/material";
import GeneralBanner from "../../components/generalBanner/GeneralBanner";
import UMSModules from "./UMSModules";
import footerbg from "../../assets/banners/bg-banner-home5.png";
import ums_video from "../../assets/gallery/ums/ums_video.mp4";

// css import
import "./css/module.css";

const UMSpage = () => {
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      rowSpacing={1}
      justifyContent="center"
      alignItems="center"
    >
      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <GeneralBanner />
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className=" ums-main-page-container"
      >
        <UMSModules ums_video={ums_video} />
      </Grid>
    </Grid>
  );
};

export default UMSpage;
