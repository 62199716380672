// gzonetech clients
import ileco from "../../assets/clients/ileco_logo.png";
import meralco from "../../assets/clients/meralco.png";
import mmsc from "../../assets/clients/mmsc.png";
import baliwag from "../../assets/clients/baliwag.png";

// ums utils
import ums1 from "../../assets/gallery/ums/bannerImage.png";
import ums2 from "../../assets/gallery/ums/dashboard1.png";
import ums3 from "../../assets/gallery/ums/dashboard2.png";

// other services card images

import discon from "../../assets/gallery/other_services/discon.jpg";
import recon from "../../assets/gallery/other_services/recon.jpg";
import read_and_bill from "../../assets/gallery/other_services/read_and_bill.jpg";
import leak_detection from "../../assets/gallery/other_services/leak_detection.jpg";
import spot_billing from "../../assets/gallery/other_services/spot_billing.jpg";
import third_party_audit from "../../assets/gallery/other_services/third_party_audit.jpg";

import { PiPlugsConnectedLight } from "react-icons/pi";
import { VscDebugDisconnect } from "react-icons/vsc";
import { IoReceiptOutline } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import { TbListSearch } from "react-icons/tb";

import { PiPlugChargingLight } from "react-icons/pi";
import { CiReceipt } from "react-icons/ci";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";
import { IoPrintOutline } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";

import meter from "../../assets/water_meter_reading.png";

export const gallery_utils = [
  // { img: gallery1, title: "", desc: "" },
  // { img: gallery2, title: "", desc: "" },
  // { img: gallery3, title: "", desc: "" },
  // { img: gallery4, title: "", desc: "" },
  // { img: gallery5, title: "", desc: "" },
  // { img: gallery6, title: "", desc: "" },
  // { img: gallery7, title: "", desc: "" },
  // { img: gallery8, title: "", desc: "" },
  // { img: gallery9, title: "", desc: "" },
];

export const ums_gallery = [
  {
    img: <PiPlugsConnectedLight />,
    title: "Reconnection",
    desc: "We train and provide field men to accomodate customer in restoring their connection",
    media: recon,
  },
  {
    img: <PiPlugChargingLight />,
    title: "Disconnection",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: discon,
  },
  {
    img: <CiReceipt />,
    title: "Read and Bill",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: read_and_bill,
  },
  {
    img: <HiOutlineViewfinderCircle />,
    title: "Leak Repair",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: leak_detection,
  },
  {
    img: <IoPrintOutline />,
    title: "Spot Billing",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: spot_billing,
  },
  {
    img: <BsGraphUpArrow />,
    title: "Third Party Audit",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: third_party_audit,
  },
];

export const client_list = [
  { img: meralco, name: "meralco" },
  { img: baliwag, name: "baliwag" },
  { img: ileco, name: "ileco" },
  { img: mmsc, name: "mmsc" },
  { img: meralco, name: "meralco" },
  { img: baliwag, name: "baliwag" },
  { img: ileco, name: "ileco" },
  { img: mmsc, name: "mmsc" },
];
