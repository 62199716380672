import React, { useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import { gallery_utils } from "../utils/GalleryUtils";
import "yet-another-react-lightbox/styles.css";
import ums_video from "../../assets/gallery/ums/ums_video.mp4";

import ReactModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

const LightboxComponent = ({ open, close }) => {
  useEffect(() => {
    const backdrop = document.querySelector(".modal-video");
    if (backdrop) {
      backdrop.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
    }
  }, [open]);
  return (
    <ReactModalVideo
      channel="custom"
      isOpen={open}
      onClose={close}
      url={ums_video}
    />
  );
};

export default LightboxComponent;
