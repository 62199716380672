import React from "react";
import Button from "@mui/material/Button";
const ButtonReusable = (props) => {
  return (
    <div>
      <Button
        disabled={props.disabled}
        variant={props.variant}
        size={props.size}
        onClick={props.onClick}
        type={props.type}
        fullWidth={props.fullWidth}
        sx={{
          color: props.color,
          padding: props.padding,
          backgroundColor: props.backgroundColor,
          height: props.height,
          width: props.width,
          fontSize: props.fontSize,
          marginRight: props.marginRight,
          marginTop: props.marginTop,
          border: props.border,
          fontWeight: props.fontWeight,

          "&:hover": {
            backgroundColor:
              props.hoverBackgroundColor || props.backgroundColor,
            border: props.hoverBorder,
            color: props.hoverColor,
          },
          textTransform: "capitalize",
        }}
      >
        {props.buttonName}
      </Button>
    </div>
  );
};

export default ButtonReusable;
