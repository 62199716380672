import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import "./css/loading_animation.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { BsSlash } from "react-icons/bs";
const LoadingAnimation = (WrappedComponent) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay to show loading animation
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);
  return (props) => {
    return loading ? <LinearProgress /> : <WrappedComponent {...props} />;
  };
};

export default LoadingAnimation;
