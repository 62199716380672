import React, { useState } from "react";

const DataStructureHooks = () => {
  let myMap = new Map([
    ["productName", ""],
    ["serialNo", ""],
    ["price", ""],
    ["desc", ""],
  ]);

  const [data, setData] = useState(myMap);

  const handleMapItems = (name, value) => {
    // setData((prevData) => new Map(prevData).set(name, value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => new Map(prevData).set(name, value));

    // handleMapItems(name, value);
  };

  console.log(data);
  return {
    data,
    handleMapItems,
    handleChange,
    myMap,
  };
};

export default DataStructureHooks;
